import React, {PropsWithChildren} from 'react';
import {makeStyles} from '@material-ui/core';
import HomeIcon from '@material-ui/icons/Home';
import ExtensionIcon from '@material-ui/icons/Extension';
import MapIcon from '@material-ui/icons/MyLocation';
import LibraryBooks from '@material-ui/icons/LibraryBooks';
import CreateComponentIcon from '@material-ui/icons/AddCircleOutline';
import LogoFull from './LogoFull';
import LogoIcon from './LogoIcon';
import {Settings as SidebarSettings, UserSettingsSignInAvatar,} from '@backstage/plugin-user-settings';
import {SidebarSearchModal} from '@backstage/plugin-search';
import {
  Link,
  Sidebar,
  sidebarConfig,
  SidebarDivider,
  SidebarGroup,
  SidebarItem,
  SidebarPage,
  SidebarScrollWrapper,
  SidebarSpace,
  useSidebarOpenState,
} from '@backstage/core-components';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import BuildIcon from '@material-ui/icons/Build';
import {AlarmAdd, AssignmentTurnedIn, Business, Note, Web} from "@material-ui/icons";
import {devToolsAdministerPermission} from '@backstage/plugin-devtools-common';
import {RequirePermission} from '@backstage/plugin-permission-react';

const useSidebarLogoStyles = makeStyles({
  root: {
    width: sidebarConfig.drawerWidthClosed,
    height: 3 * sidebarConfig.logoHeight,
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    marginBottom: -14,
  },
  link: {
    width: sidebarConfig.drawerWidthClosed,
    marginLeft: 24,
  },
});

const SidebarLogo = () => {
  const classes = useSidebarLogoStyles();
  const { isOpen } = useSidebarOpenState();

  return (
    <div className={classes.root}>
      <Link to="/" underline="none" className={classes.link} aria-label="Home">
        {isOpen ? <LogoFull /> : <LogoIcon />}
      </Link>
    </div>
  );
};

export const Root = ({ children }: PropsWithChildren<object>) => (
  <SidebarPage>
    <Sidebar>
      <SidebarLogo />
      <SidebarGroup label="Search" icon={<SearchIcon />} to="/search">
        <SidebarSearchModal />
      </SidebarGroup>
      <SidebarDivider />
      <SidebarGroup label="Menu" icon={<MenuIcon />}>
        {/* Global nav, not org-specific */}
        <SidebarItem icon={HomeIcon} to="catalog" text="Home" />
        <SidebarItem icon={ExtensionIcon} to="api-docs" text="APIs" />
        <SidebarItem icon={AlarmAdd} to="/opsgenie" text="OpsGenie" />
        <SidebarItem icon={LibraryBooks} to="docs" text="Docs" />
        <SidebarItem icon={CreateComponentIcon} to="create" text="Create..." />
        {/* End global nav */}
        <SidebarDivider />
        <SidebarScrollWrapper>
          <SidebarItem icon={MapIcon} to="tech-radar" text="Tech Radar" />
          <SidebarDivider />
          <SidebarGroup label="External Sites">
            <SidebarItem icon={AssignmentTurnedIn} to="https://jira.media-saturn.com/" text="Jira" target="_blank"/>
            <SidebarItem icon={LibraryBooks} to="https://confluence.media-saturn.com/" text="Confluence" target="_blank"/>
            <SidebarItem icon={Note} to="https://docs.mediamarktsaturn.tech/" text="Eng. Community" target="_blank"/>
            <SidebarItem icon={Business} to="https://mediamarktsaturn.leanix.net" text="LeanIX" target="_blank"/>
            <SidebarItem icon={Web} to="https://mediasaturn.sharepoint.com/" text="SharePoint" target="_blank"/>
            <SidebarItem icon={AlarmAdd} to="https://mediamarktsaturn.app.eu.opsgenie.com/" text="Opsgenie" target="_blank"/>
          </SidebarGroup>
        </SidebarScrollWrapper>
      </SidebarGroup>
      <SidebarSpace />
      <SidebarDivider />
      <SidebarGroup
        label="Settings"
        icon={<UserSettingsSignInAvatar />}
        to="/settings"
      >
        <SidebarSettings />
           <RequirePermission
             permission={devToolsAdministerPermission}
             errorPage={<></>}>
              <SidebarItem icon={BuildIcon} to="devtools" text="DevTools" />
        </RequirePermission>
      </SidebarGroup>
    </Sidebar>
    {children}
  </SidebarPage>
);
